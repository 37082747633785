import request from '@/utils/request'
const baseURL = '/baseapi'
const stickupApi = {
  DelLog: '/admin/stickup/delLog',
  FileLog: '/admin/stickup/fileLog'
}

/**
 * login func
 * parameter: {
 *     username: '',
 *     password: '',
 *     remember_me: true,
 *     captcha: '12345'
 * }
 * @param parameter
 * @returns {*}
 */

export function delLog (parameter) {
  return request({
    baseURL: baseURL,
    url: stickupApi.DelLog,
    method: 'post',
    data: parameter
  })
}

export function delfileLog (parameter) {
  return request({
    baseURL: baseURL,
    url: stickupApi.FileLog,
    method: 'post',
    data: parameter
  })
}
