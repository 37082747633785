<template>
  <page-header-wrapper :title="false">
    <a-card title="数据清理" :bordered="false">
      <a-spin :spinning="loading">
        <div style="color: #1890ff">
          一键清理日志：会清空站点日志文件
        </div>
        <div style="color: #1890ff">
          一键清理垃圾文件：会清空站点产生的垃圾文件
        </div>
        <a-divider :dashed="true" />
        <span style="margin-left: 180px; margin-right: 20px;">
          <a-button
            type="primary"
            :loading="logLoading"
            @click="delLogApi"
          >
            一键清理日志
          </a-button>
        </span>
        <span>
          <a-button
            type="primary"
            :loading="fileLoading"
            @click="delFileApi"
          >
            一键清理垃圾文件
          </a-button>
        </span>
      </a-spin>
    </a-card>
  </page-header-wrapper>
</template>

<script>

import { delfileLog, delLog } from '@/api/stickup'
export default {
  name: 'Log',
  components: {
  },
  data () {
    return {
      loading: false,
      logLoading: false,
      redisLoading: false,
      fileLoading: false
    }
  },
  filters: {
  },
  created () {
  },
  methods: {
    delLogApi () {
      const _this = this
      _this.loading = true
      _this.logLoading = true
      console.log('delLogApi')
      delLog().then((res) => {
        if (res.errorCode === 0) {
          setTimeout(() => {
            _this.$message.success('清除日志成功')
            _this.loading = false
            _this.logLoading = false
          }, 1000)
        } else {
          _this.$message.error(res.errorMsg)
          _this.loading = false
          _this.logLoading = false
        }
      }).catch((err) => {
        _this.loading = false
        _this.logLoading = false
        _this.$message.error('系统错误')
        console.log(err)
      })
    },
    delFileApi () {
      const _this = this
      _this.loading = true
      _this.fileLoading = true
      console.log('delFileApi')
      delfileLog().then((res) => {
        if (res.errorCode === 0) {
          setTimeout(() => {
            _this.$message.success('清除垃圾文件成功')
            _this.loading = false
            _this.fileLoading = false
          }, 1000)
        } else {
          _this.$message.error(res.errorMsg)
          _this.loading = false
          _this.fileLoading = false
        }
      }).catch((err) => {
        _this.loading = false
        _this.fileLoading = false
        _this.$message.error('系统错误')
        console.log(err)
      })
    }
  }
}
</script>
